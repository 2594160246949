<template>
	
  <div style="min-width: 1440px; max-width: 1920px; margin: 0 auto">
    <Header />
    <Banner
      :bannerUrl="bannerUrl"
      :subTilte="subTilte"
      :content="content"
      v-if="!hideBanner"
    />
    <LeftNav v-if="!hideLeft" />
    <!-- <RightNav /> -->
    <slot />
    <!-- <NavFooter /> -->
<!-- 	<div class="Sidebar">
	    <div class="box1">
	      <div class="picture5" id="div1" @click="goToRegister()">
	        <a :href="registerAddress">
	        <img src="@/static/common/zhuce.png" style="width:30px; height:30px; vertical-align: text-bottom;position: absolute;left: 29%;top: 10px;">
	        <p style="width:100%;font-size: 14px;color: #fff;position: absolute;bottom: 4px;text-align: center;">用户注册</p>
	        </a>
	      </div>
	    </div>
	    <div class="box2" @click="goToLogin()">
	      <div class="picture6" id="div5">
	        <a :href="address">
	        <img src="@/static/common/denglu.png" style="width:30px; height:30px; vertical-align: text-bottom;position: absolute;left: 29%;top: 10px;">
	        <p  style="width:100%;font-size: 14px;color: #fff;position: absolute;bottom: 4px;text-align: center;">系统登录</p>
	        </a>
	      </div>
	    </div>
	    <div class="box3" style="display: flex;justify-content: flex-end;margin-top: 9px;">
	      <div class="title1" id="div3">
	        <div class="title1-box">
	          <p style="margin:0; margin-left:10px; font-size:18px; margin-bottom:5px;">联系电话：</p>
	          <p style="margin:0; margin-left:10px; font-size:18px;">xxxxxxxxxxxxx</p>
	        </div>
	      </div>
	       <div class="picture7" id="div4" @mousemove="pop">
	          <img src="@/static/common/dianhua.png" style="width:30px; height:30px; vertical-align: text-bottom;position: absolute;left: 29%;top: 10px;">
	        <p style="width:100%;font-size: 14px;color: #fff;position: absolute;bottom: 4px;text-align: center;">联系电话</p>
	       </div>
	    </div>
		
		<div class="box4" style="display: flex;justify-content: flex-end;margin-top: 9px;">
		  <div class="title1" id="div7">
		    <div class="title1-box">
		      <p style="margin:0; margin-left:10px; font-size:18px; margin-bottom:5px;">在线客服：</p>
		      <p style="margin:0; margin-left:10px; font-size:18px;">xxxxxxxxxxxxx</p>
		    </div>
		  </div>
		   <div class="picture8" id="div8" @mousemove="pop1">
		      <img src="@/static/common/QQ.png" style="width:30px; height:30px; vertical-align: text-bottom;position: absolute;left: 29%;top: 10px;">
		    <p style="width:100%;font-size: 14px;color: #fff;position: absolute;bottom: 4px;text-align: center;">在线客服</p>
		   </div>
		</div>
	  </div> -->
  </div>
</template>

<script>
import Header from "./components/Header/Header";
import LeftNav from "./components/LeftNav/LeftNav";
import Banner from "./components/Banner/Banner";
import NavFooter from "./components/Footer/Footer";
import RightNav from "./components/RightNav/RightNav";
export default {
  props: ["bannerUrl", "subTilte", "content", "hideLeft", "hideBanner"],
  components: { Header, LeftNav, Banner, NavFooter, RightNav },
  setup() {},
  methods: {
	  goToLogin() {
	    // console.log(process.env);
	    window.open(process.env.VUE_APP_SEVERCE_PLATFORM, "_blank");
	    // location.href = process.env.VUE_APP_SEVERCE_PLATFORM;
	  },
	  goToRegister() {
	    window.open(process.env.VUE_APP_SEVERCE_REGISTER, "_blank");
	    // location.href = process.env.VUE_APP_SEVERCE_REGISTER;
	  },
    toHelpCenter() {
      this.$parent.toHelpCenter();
    },
	    pop(){
	        var oDiv = document.getElementById("div4");
	        var oD = document.getElementById("div3");
	        oDiv.onmouseover = function () {
	        console.log('2222222222222222222')
	        oD.style.display = "block"
	        };
	        oDiv.onmouseout = function () {
	        oD.style.display = "none"
	        };
	    },
		pop1(){
		    var oDiv = document.getElementById("div8");
		    var oD = document.getElementById("div7");
		    oDiv.onmouseover = function () {
		    console.log('2222222222222222222')
		    oD.style.display = "block"
		    };
		    oDiv.onmouseout = function () {
		    oD.style.display = "none"
		    };
		},
  },
};
</script>
<style type="text/css">
	/* .Sidebar{position: fixed;top: 40%;right: 101px;z-index:99;}
	.box1{display: flex;margin-bottom: 10px; justify-content: flex-end;}
	.picture5{width: 74px;height: 74px;background: linear-gradient(135deg, rgba(67, 200, 238, 1) 0%, rgba(106, 120, 252, 1) 100%);border: 5px solid #D9E4EE;border-radius: 5px;position: relative;}
	.title1{background-color: #fff;width: 180px;height: 84px; display:none;}
	.title1-box{ margin-top: 20px;justify-content: flex-end; }
	.box2{display: flex;justify-content: flex-end;}
	.box4{display: flex;justify-content: flex-end;}
	.picture6{width: 74px;height: 74px;background: linear-gradient(135deg, rgba(67, 200, 238, 1) 0%, rgba(106, 120, 252, 1) 100%);border: 5px solid #D9E4EE;border-radius: 5px;position: relative;}
	.picture7{width: 74px;height: 74px;background: linear-gradient(135deg, rgba(67, 200, 238, 1) 0%, rgba(106, 120, 252, 1) 100%);border: 5px solid #D9E4EE;border-radius: 5px;position: relative;}
	.picture8{width: 74px;height: 74px;background: linear-gradient(135deg, rgba(67, 200, 238, 1) 0%, rgba(106, 120, 252, 1) 100%);border: 5px solid #D9E4EE;border-radius: 5px;position: relative;}
	.titlebox{width:62%;height:10px; display:flex;align-items: center;margin:13px auto;} */
</style>