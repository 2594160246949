<template>
  <div class="banner_wrap" :style="getImgUrl">
    <div class="banner_wrap_content">
      <!-- <div class="banner_subTitle">{{ subTilte }}</div>
      <div class="banner_line"></div>
      <div class="banner_content">
        {{ content }}
      </div> -->
    </div>
  </div>
</template>



<script>
import imgData from "@/data.js";
import { reactive, computed } from "vue";

export default {
  props: {
    bannerUrl: String,
    subTilte: String,
    content: String,
  },
  setup(props) {
    const getImgUrl = computed(() => {
      const imgUrl = props.bannerUrl;
      // const imgUrl = imgData.indexBannerImg;
      return "background-image:url('" + imgUrl + "')";
    });
    const data = reactive({
      bannerUrl: getImgUrl.value,
    });
    return {
      ...data,
      ...imgData,
      getImgUrl,
    };
  },
};
</script>

<style >
@import url("./Banner.css");
</style>