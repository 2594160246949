<template>
 <!-- <div class="header_wrap"> -->
   <!-- <div class="logo" style="cursor: pointer" @click="goToIndex">
      <img :src="logoImg" />
    </div>
 -->
    <!-- <div class="header_right"> -->
      <!-- <div class="search_icon_wrap">
        <div class="search_icon" style="cursor: pointer">
          <img :src="searchIcon" style="width: 25px; height: 25px" />
        </div>
      </div> -->

     <!-- <div class="header_console_btn_wrap" @click="goToLogin()">
        <div class="header_console_btn" style="cursor: pointer">
          <img
            :src="noticeIcon"
            style="width: 25px; height: 25px; margin: 0 15px"
          />
          <span>控制台</span>
        </div>
      </div> -->

     <!-- <div class="header_line"></div> -->

      <!-- <div class="header_btn_wrap" v-if="!haveToken"> -->
        <!-- <div
          class="header_login_btn"
          style="cursor: pointer"
          @click="goToLogin()"
        >
          登录
        </div> -->
        <!-- <div
          class="header_register_btn"
          style="cursor: pointer"
          @click="goToRegister()"
        >
          立即注册
        </div> -->
      <!-- </div> -->
    <!-- </div> -->
  <!-- </div> -->

  <div class="header_li_wrap" style="width: 1300px;
    margin: 0 auto;">
    <div class="headcer_li_logo" style="margin: 0px;">
		<div class="logo" style="cursor: pointer;margin: 0px;" @click="goToIndex">
		      <img :src="logoImg" />
		    </div>
	</div>
    <div class="header_li_right">
      <ul>
        <!-- width:${100 / listData.length}%; -->
        <li
		:id="item.categoryOrder"
          v-for="item in listData"
          :key="item.code"
          class="header_li_right_item"
          :style="`cursor:pointer`"
          @click="pushRouter(item)"
        >
          <p>
            {{
              item.categoryName == "新闻中心页" ? "新闻中心" : item.categoryName
            }}
          </p>
          <!-- <img :src="activeLiIcon" :style="activePage == item.url ? '' : 'visibility: hidden'" /> -->
		  <div class="on" :style="activePageUrl == item.categoryOrder ? '' : 'visibility: hidden'">
		  	
		  </div>
          <!-- <img
            :src="activeLiIcon"
            
          /> -->
        </li>
      </ul>
	  
    </div>
	<div
	  style="cursor: pointer;width: 120px;
height: 40px;background: linear-gradient(135deg, rgba(67, 200, 238, 1) 0%, rgba(106, 120, 252, 1) 100%);color: #fff;display: flex;justify-content: center;align-items: center;border-radius: 6px;"
	  @click="goToLogin()"
	>
	<img
	  src="@/static/common/tc.png" style="margin-right: 7px;"
	/>
	  登录
	</div>
  </div>
</template>

<script>
import imgData from "@/data.js";
import { reactive, computed } from "vue";
import { useRouter } from "vue-router";
import { mainStore } from "@/store";
import { storeToRefs } from "pinia";
export default {
  setup() {
	const storeOfmain = mainStore();
    const store = mainStore();
    const { navBar } = storeToRefs(store);
    const activePageUrl = computed(() => {
      return storeToRefs(store).activePageUrl;
    });
    const listData = computed(() => storeToRefs(store).navBar);
    const router = useRouter();
    const goToIndex = () => {
      store.setactivePageUrl("/index");
      router.push({ path: "/index" });
    };
	// const toNewsPage = () => {
	//   storeOfmain.setData("news_ActiveType", "use");
	//   router.push({
	// 	path: "/ListOfNews",
	//   });
	// };
	// const toNewsPage1 = () => {
	//   storeOfmain.setData("news_ActiveType", "help");
	//   router.push({
	// 	path: "/ListOfNews",
	//   });
	// };
    const pushRouter = (item) => {
		const ceshi = item.categoryOrder
      const { categoryType, link } = item;
	  if(item.categoryName == '使用指南'){
		  const path = "/ListOfNews"
		  storeOfmain.setData("news_ActiveType", "use");
		  router.push({
		  		path: "/ListOfNews",
		  });
		  store.setData("news_ActiveId", item.id);
		  store.newsReloadTableData();
		  store.setactivePageUrl(item.categoryOrder);
	  }else if(item.categoryName == '帮助中心'){
		   const path = "/ListOfNews"
		  storeOfmain.setData("news_ActiveType", "help");
		  router.push({
		  		path: "/ListOfNews",
		  });
		  store.setData("news_ActiveId", item.id);
		  store.newsReloadTableData();
		  store.setactivePageUrl(item.categoryOrder);
	  }else if(item.categoryName == '操作流程'){
		   const path = "/ListOfNews"
		  storeOfmain.setData("news_ActiveType", "policy");
		  router.push({
		  		path: "/ListOfNews",
		  });
		  store.setData("news_ActiveId", item.id);
		  store.newsReloadTableData();
		  store.setactivePageUrl(item.categoryOrder);
	  }else if(item.categoryName == '下载中心'){
		   const path = "/ListOfNews"
		  storeOfmain.setData("news_ActiveType", "tool");
		  router.push({
		  		path: "/ListOfNews",
		  });
		  store.setData("news_ActiveId", item.id);
		  store.newsReloadTableData();
		  store.setactivePageUrl(item.categoryOrder);
	  }else if (categoryType == 1) {
        const path = link == "/" ? "/index" : link;
        router.push({ path });
        store.setactivePageUrl(item.categoryOrder);
      } else {
        window.open(link, "_blank");
      }
    };
    const data = reactive({
      listData,
      activePageUrl,
    });
    return {
      ...data,
      ...imgData,
      pushRouter,
      activePage: "/index",
      haveToken: false,
      goToIndex,
    };
  },
  created() {
	  // this.activePageUrl = '/index'
    let href = window.location.href;
    let origin = window.location.origin + "/";
    this.activePage =
      href.replace(origin, "") == "BiddingInfo"
        ? ""
        : "/" + href.replace(origin, "");

    let arr = document.cookie.split(";");
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].indexOf("saber-access-token") !== -1) {
        this.haveToken = true;
      }
    }
  },
  methods: {
    goToLogin() {
      // console.log(process.env);
      window.open(process.env.VUE_APP_SEVERCE_PLATFORM, "_blank");
      // location.href = process.env.VUE_APP_SEVERCE_PLATFORM;
    },
    goToRegister() {
      window.open(process.env.VUE_APP_SEVERCE_REGISTER, "_blank");
      // location.href = process.env.VUE_APP_SEVERCE_REGISTER;
    },
  },
};
</script>

<style >
@import url("./Header.css");
.notShow {
            display: none;
        }
		.on{
			width: 55px;
			height: 4px;
			opacity: 1;
			background: rgba(10, 96, 234, 1);
			position: absolute;
			    bottom: -21px;
		}
</style>